import React, { useState } from 'react';
import { weekdayArray } from '../../common/utils';
import {
  CourseCardContainer,
  CourseCardBody,
  CourseCardDivideLine,
  CourseCardName,
  CourseWordItem,
} from './styles/FilteredCourseCardStyle';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import Swal from 'sweetalert2';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { getFinalCoursePrice } from '../../faunadb/queries/course';
import { applyCourse } from '../../faunadb/queries/student';

const getFav = (id) => {
  const list = JSON.parse(localStorage.getItem('fav-list')) || [];
  if (list.indexOf(id) > -1) {
    return true;
  } else return false;
};

const addOrRemoveFav = (id) => {
  const list = JSON.parse(localStorage.getItem('fav-list')) || [];
  if (list.indexOf(id) > -1) {
    list.splice(list.indexOf(id), 1);
    localStorage.setItem('fav-list', JSON.stringify(list));
    return false;
  } else {
    list.push(id);
    localStorage.setItem('fav-list', JSON.stringify(list));
    return true;
  }
};

const FilteredCourseCard = ({ course, showFavOnly, refreshActiveTerm }) => {
  const [loading, setLoading] = useState(false);
  const [fav, setFav] = useState(getFav(`c${course.courseId}`));
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { client: clientV10 } = useFaunav10Context();
  const queryClient = useQueryClient();

  // const beforeEvening =
  //   parseInt(course.courseStartAt.split(':')[0]) < 18 &&
  //   parseInt(course.courseStartAt.split(':')[1]) < 45;

  return (
    <>
      <CourseCardContainer>
        <CourseCardBody>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <div
              onClick={() => {
                setFav(addOrRemoveFav(`c${course.courseId}`));
              }}
            >
              {fav ? <AiFillStar size={20} /> : <AiOutlineStar size={20} />}
            </div>
            <CourseCardName>{course.courseName}</CourseCardName>
            <CourseCardDivideLine />
            <p>{course.classroomName}</p>
            {/* <CourseTeacherIconContainer>
              <img src={course.teacherIcon} alt="icon" />
            </CourseTeacherIconContainer> */}
            {/* <CourseWordItem>
              {weekdayArray[course.courseWeekday - 1]}
            </CourseWordItem> */}
            <CourseWordItem>
              {course.courseStartAt} ~ ({course.courseDuration} mins)
            </CourseWordItem>
            <CourseWordItem>{course.teacherName}</CourseWordItem>
            {/* <CourseCardDivideLine /> */}
            {/* <CourseWordItem>Price: ${course.coursePrice}</CourseWordItem> */}
            <CourseWordItem>
              Remaining Seats: ({course.courseQuota})
            </CourseWordItem>

            <CourseCardDivideLine />
            {isAuthenticated && (
              <button
                style={{
                  backgroundColor: course.courseQuota <= 0 || 'gray',
                }}
                disabled={course.courseQuota <= 0}
                className={
                  course.courseQuota <= 0
                    ? 'px-11 py-2 bg-gray-500 rounded-full text-white'
                    : 'px-11 py-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full text-white'
                }
                onClick={() => {
                  getFinalCoursePrice(
                    clientV10,
                    course.courseId
                  )
                    .then((price) => {
                      Swal.fire(
                        {
                          title: `${weekdayArray[course.courseWeekday - 1]} ${course.courseStartAt
                            } ${course.courseName}`,
                          confirmButtonText: 'Apply',
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          showCancelButton: true,
                          icon: 'question',
                          text: `Final price: $${price}`,
                          footer:
                            'Classes are not subject to refund, deferral or transfer under any circumstances. All classes are to be used within the term. All final decisions are reserved by Spinderella Pole Studio.',
                          showLoaderOnConfirm: true,
                          preConfirm: () => {
                            setLoading(true);
                            return applyCourse(
                              clientV10,
                              course.courseId
                            )
                              .then((response) => {
                                // if (response.data) {
                                  toast.success(
                                    <FormattedMessage
                                      id="APPLY_SUCCESS"
                                      defaultMessage={
                                        'Course apply successfully'
                                      }
                                    />
                                  );
                                  Swal.fire({
                                    icon: 'success',
                                    title: `${weekdayArray[course.courseWeekday - 1]
                                      } ${course.courseStartAt} ${course.courseName
                                      }`,
                                    text: 'Applied!',
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                  });
                                // }
                                // TODO add refresh
                              }).catch((error) => {
                                if (error?.code) {
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text:
                                      error?.code === 'constraint_failure'
                                        ? 'You are already in this course!'
                                        : error?.abort,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                  });
                                }
                              }).finally(() => {
                                queryClient.invalidateQueries(['activeTerm', 'v10']);
                                queryClient.invalidateQueries(['my_profile']);
                                setLoading(false);
                              });
                          },
                        },
                      );
                    });
                }}
              >
                {course.courseQuota <= 0
                  ? 'Full'
                  : !loading
                    ? 'Apply'
                    : 'Applying...'}
              </button>
            )}
            {!isAuthenticated && (
              <button
                className="px-11 py-2 bg-gray-400 hover:bg-gray-500 rounded-full text-white"
                onClick={loginWithRedirect}
              >
                Login
              </button>
            )}
          </div>
        </CourseCardBody>
      </CourseCardContainer>
    </>
  );
};

export default FilteredCourseCard;
